<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      "Bank Account": "Conta Bancária",
      Save: "Salvar",
    },
    es: {
      "Bank Account": "Cuenta Bancaria",
      Save: "Salvar",
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      alert: {
        banco: { type: "", message: "" },
      },

      loading: false,

      bancos: [],
      banco: "1",
      conta: {
        numero: "",
        digito: "",
      },
      agencia: {
        numero: "",
        digito: "",
      },
    };
  },
  validations: {
    banco: { required },
    conta: {
      numero: { required },
      digito: {},
    },
    agencia: {
      numero: { required },
      digito: {},
    },
  },
  methods: {
    getBanks() {
      api.get("banks").then((response) => {
        if (response.data.status == "success") {
          this.bancos = response.data.list;
        }
      });
    },
    getBank() {
      api.get("user/bank").then((response) => {
        if (response.data.status == "success") {
          this.banco = response.data.banco.numero;
          this.agencia.numero = response.data.agencia.numero;
          this.agencia.digito = response.data.agencia.digito;
          this.conta.numero = response.data.conta.numero;
          this.conta.digito = response.data.conta.digito;
        }
      });
    },
    bancoSubmit() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        api
          .post("user/bank", {
            banco: this.banco,
            agencia: {
              numero: this.agencia.numero,
              digito: this.agencia.digito,
            },
            conta: {
              numero: this.conta.numero,
              digito: this.conta.digito,
            },
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$v.$reset();

              this.alert.banco.type = "alert-success";
              this.alert.banco.message =
                "Os dados da sua conta bancária foram salvos com sucesso.";
            } else {
              this.alert.banco.type = "alert-danger";
              this.alert.banco.message = response.data.message;
            }

            this.loading = false;
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
            }
          });
      }
    },
  },
  mounted() {
    this.getBanks();
    this.getBank();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-7">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Bank Account") }}</h4>
        </div>
        <div v-if="alert.banco.message" :class="'alert ' + alert.banco.type">
          {{ t(alert.banco.message) }}
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <b-form class="p-3" @submit.prevent="bancoSubmit">
              <b-form-group id="banco" :label="t('Banco')" label-for="banco">
                <select v-model="banco" class="custom-select mb-2">
                  <option v-for="b in bancos" :key="b.number" :value="b.number">
                    {{ b.name }}
                  </option>
                </select>
                <div v-if="$v.banco.$error" class="invalid-feedback">
                  <span v-if="!$v.banco.required">{{
                    t("New password is required.")
                  }}</span>
                </div>
              </b-form-group>
              <div class="row">
                <b-form-group
                  id="agencia.numero"
                  class="col-8"
                  :label="t('Agência')"
                  label-for="agencia.numero"
                >
                  <b-form-input
                    id="agencia.numero"
                    v-model="agencia.numero"
                    type="text"
                    :class="{ 'is-invalid': $v.agencia.numero.$error }"
                  ></b-form-input>
                  <div v-if="$v.agencia.numero.$error" class="invalid-feedback">
                    <span v-if="!$v.agencia.numero.required">{{
                      t("A agência é obrigatório.")
                    }}</span>
                  </div>
                </b-form-group>
                <b-form-group
                  id="agencia.digito"
                  class="col-4"
                  :label="t('Digito')"
                  label-for="agencia.digito"
                >
                  <b-form-input
                    id="agencia.digito"
                    v-model="agencia.digito"
                    type="text"
                    :class="{ 'is-invalid': $v.agencia.digito.$error }"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  id="conta.numero"
                  class="col-8"
                  :label="t('Conta')"
                  label-for="conta.numero"
                >
                  <b-form-input
                    id="conta.numero"
                    v-model="conta.numero"
                    type="text"
                    :class="{ 'is-invalid': $v.conta.numero.$error }"
                  ></b-form-input>
                  <div v-if="$v.conta.numero.$error" class="invalid-feedback">
                    <span v-if="!$v.conta.numero.required">{{
                      t("A conta é obrigatório.")
                    }}</span>
                  </div>
                </b-form-group>
                <b-form-group
                  id="conta.digito"
                  class="col-4"
                  :label="t('Digito')"
                  label-for="conta.digito"
                >
                  <b-form-input
                    id="conta.digito"
                    v-model="conta.digito"
                    type="text"
                    :class="{ 'is-invalid': $v.conta.digito.$error }"
                  ></b-form-input>
                  <div v-if="$v.conta.digito.$error" class="invalid-feedback">
                    <span v-if="!$v.conta.digito.required">{{
                      t("O digito da conta é obrigatório.")
                    }}</span>
                  </div>
                </b-form-group>
              </div>
              <div class="mt-4">
                <b-button
                  :disabled="
                    this.loading == true ||
                    !this.banco ||
                    !this.agencia.numero ||
                    !this.conta.numero ||
                    !this.conta.digito
                  "
                  type="submit"
                  variant="default"
                >
                  {{ t("Save") }}
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
